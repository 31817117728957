// @ts-nocheck

import { ListItemButton, ListSubheader, Popover } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { bgBlur } from '@/utils/cssStyles';

import type { NavItemDesktopProps } from '../types';

type ListItemProps = Omit<NavItemDesktopProps, 'item'>;

export const ListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== 'active' && prop !== 'open' && prop !== 'isOffset' && prop !== 'subItem',
})<ListItemProps>(({ active, open, isOffset, subItem, theme }) => {
  const dotActive = {
    content: '""',
    borderRadius: '50%',
    position: 'absolute',
    width: 6,
    height: 6,
    left: -14,
    opacity: 0.48,
    backgroundColor: 'currentColor',
  };

  return {
    ...theme.typography.body2,
    fontWeight: 500,
    padding: 0,
    height: '100%',
    color: theme.palette.secondary.main,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
      opacity: 0.48,
      backgroundColor: 'transparent',
    },
    // Sub item
    ...(subItem && {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
    }),
    // isOffset
    ...(isOffset && {
      color: theme.palette.text.primary,
    }),
    // Active
    ...(active && {
      color: theme.palette.primary.main,
      '&::before': dotActive,
    }),
    // Active sub item
    ...(active &&
      subItem && {
        ...theme.typography.subtitle2,
        color: theme.palette.text.primary,
        '&::before': {
          ...dotActive,
          color: theme.palette.primary.main,
        },
      }),
    // Open
    ...(open && {
      opacity: 0.48,
    }),

    [theme.breakpoints.down('1280')]: {
      fontSize: 13,
    },
  };
});

export const StyledMenu = styled('div')(({ theme }) => ({
  ...bgBlur({
    opacity: 0.94,
    color: theme.palette.background.default,
  }),
  margin: 'auto',
  display: 'grid',
  position: 'fixed',
  alignItems: 'flex-start',
  top: '60px!important',
  marginLeft: '8%!important',
  overflowY: 'hidden!important',
  zIndex: theme.zIndex.modal,
  padding: theme.spacing(3, 3, 3, 3),
  boxShadow: theme.customShadows.z24,
  maxWidth: theme.breakpoints.values.lg,
  gridTemplateColumns: 'repeat(12, 1fr)',
  borderRadius: Number(theme.shape.borderRadius) * 2,
  border: `solid 1px ${alpha(theme.palette.grey[500], 0.16)}`,
}));

// ----------------------------------------------------------------------

export const StyledSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  padding: 0,
  fontSize: 11,
  color: theme.palette.text.primary,
}));

export const StyledPopover = styled(Popover)(
  ({ theme, propHeight }: { theme: any; propHeight: any }) => ({
    pointerEvents: 'none',
    '& .MuiPopover-paper': {
      left: 0,
      right: 0,
      margin: 'auto',
      position: 'fixed',
      pointerEvents: 'auto',
      height: propHeight,
      background: 'rgba(0,0,0,0.0)',
      borderStyle: 'none!important',
      boxShadow: 'none!important',
      overflowY: 'hidden!important',
    },
  }),
);

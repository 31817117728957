// @ts-nocheck
import Link from 'next/link';

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

import Iconify from '@/components/iconify/Iconify';
import { TitleH6 } from '@/theme/OwnGlobalStylesComponents/StyleText';

import { ItemBox, ListItem, SeparatorBox } from './styles';

import type { NavItemMobileProps } from '../types';

export default function NavItem({
  item,
  open,
  active,
  isExternalLink,
  openCloseModal,
  ...other
}: NavItemMobileProps) {
  const { title, path, icon, children, external } = item;
  const { t } = useTranslation();

  const renderContent = (
    <ListItem active={active} {...other} sx={{ height: '8vh' }}>
      <ListItemIcon> {icon} </ListItemIcon>

      <ListItemText>
        <Box>
          {open ? (
            <TitleH6>
              <strong>{t(title)}</strong>
            </TitleH6>
          ) : (
            <TitleH6>{t(title)}</TitleH6>
          )}
        </Box>
      </ListItemText>

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          color={open ? 'primary.main' : 'grey.main'}
          sx={{ mr: '4vh' }}
        />
      )}
    </ListItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target={external ? '_blank' : ''} style={{ textDecoration: 'none' }}>
        {renderContent}
        <ItemBox>
          <SeparatorBox />
        </ItemBox>
      </Link>
    );
  }

  // Has child
  if (children) {
    return (
      <>
        {renderContent}
        <ItemBox>
          <SeparatorBox />
        </ItemBox>
      </>
    );
  }

  // Default
  return (
    <Link
      href={path}
      target={external ? '_blank' : ''}
      style={{ textDecoration: 'none' }}
      onClick={openCloseModal}
    >
      {renderContent}
      <ItemBox>
        <SeparatorBox />
      </ItemBox>
    </Link>
  );
}

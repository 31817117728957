// @ts-nocheck

import { Stack } from '@mui/material';

import { useLayoutStore } from '@/stores/layout/layout.store';

import NavList from './NavList';

import type { NavProps } from '../types';

const NavDesktop = ({ isOffset, data }: NavProps) => {
  const country = useLayoutStore((state) => state.country);
  return (
    <Stack
      component="nav"
      direction="row"
      spacing={{ lg: 2, xl: 3 }}
      sx={{ mx: { lg: 3, xl: 5 }, height: 1 }}
    >
      {data.map(
        (list) =>
          list.countries.includes(country) && (
            <NavList
              key={list.title + list.path}
              item={list}
              depth={1}
              hasChild={!!list.children}
            />
          ),
      )}
    </Stack>
  );
};

export default NavDesktop;

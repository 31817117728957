// @ts-nocheck

import Box from '@mui/material/Box';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { FabButtonAnimate, FabButtonAnimateOutlined, varFade } from '@/components/animate';
import { PATH_ENTER, PATH_SEARCH, PATH_SIGNUP, PATH_TALK_ADVISOR_CL } from '@/routes/paths';
import { useLayoutStore } from '@/stores/layout/layout.store';
import { mapEvent } from '@/utils/goggleAnalytics/gtag';

import { loginEvent, registerEvent, trackingEvent } from '../../constants';

export default function Buttons() {
  const { t } = useTranslation();
  const country = useLayoutStore((state) => state.country);
  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', mt: '5vh', mx: 3, justifyContent: 'center' }}
      >
        <m.div variants={varFade().in}>
          <FabButtonAnimate
            key="btn-register"
            data-cy="btn-register-mobile"
            variant="extended"
            target="_blank"
            size="medium"
            href={country === 'CL' ? PATH_TALK_ADVISOR_CL : PATH_SIGNUP}
            onClick={() => mapEvent(registerEvent)}
          >
            {country === 'CL'
              ? t('COMMON.ACTIONS.REGISTER_CL')
              : t('LAYOUT.HEADER.ACTIONS.CREATE_MOBILE')}
          </FabButtonAnimate>
        </m.div>
      </Box>
      <Box
        sx={{ display: 'flex', alignItems: 'center', mt: '3vh', mx: 3, justifyContent: 'center' }}
      >
        <m.div variants={varFade().in}>
          <FabButtonAnimateOutlined
            key="btn-tracking"
            data-cy="btn-tracking-mobile"
            variant="outlinedExtended"
            target="_blank"
            href={PATH_SEARCH}
            size="medium"
            color="primary"
            onClick={() => mapEvent(trackingEvent)}
          >
            {t('LAYOUT.HEADER.ACTIONS.TRACKING_MOBILE')}
          </FabButtonAnimateOutlined>
        </m.div>
        <m.div variants={varFade().in}>
          <FabButtonAnimateOutlined
            key="btn-login"
            data-cy="btn-login-mobile"
            variant="outlinedExtended"
            target="_blank"
            href={PATH_ENTER}
            size="medium"
            color="primary"
            onClick={() => mapEvent(loginEvent)}
          >
            {t('LAYOUT.HEADER.ACTIONS.LOGIN_MOBILE')}
          </FabButtonAnimateOutlined>
        </m.div>
      </Box>
    </>
  );
}

// @ts-nocheck

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Collapse, Stack } from '@mui/material';

import useActiveLink from '@/hooks/useActiveLink';
import { useLayoutStore } from '@/stores/layout/layout.store';

import Submenu from '../Submenu/Submenu';
import NavItem from './NavItem';

import type { NavMenu } from '../types';

interface NavListProps {
  item: NavMenu;
}

export default function NavList({ item }: NavListProps) {
  const { pathname } = useRouter();
  const country = useLayoutStore((state) => state.country);
  const { path, children } = item;
  const { isExternalLink } = useActiveLink(path);
  const [open, setOpen] = useState(false);

  return (
    <>
      <>
        <NavItem
          item={item}
          open={open}
          onClick={() => setOpen(!open)}
          active={pathname === path}
          isExternalLink={isExternalLink}
        />

        <Collapse in={open} unmountOnExit>
          <Stack
            sx={{
              '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                height: 160,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                bgcolor: 'background.neutral',
                backgroundRepeat: 'no-repeat',
                backgroundImage: 'url(/assets/illustrations/illustration_dashboard.png)',
                '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
              },
            }}
          >
            {children
              ?.filter((child) => child.countries.includes(country))
              .map((filteredChild) => (
                <Link
                  href={filteredChild.link}
                  target={filteredChild.external === true ? '_blank' : ''}
                  style={{ textDecoration: 'none' }}
                  key={filteredChild.name}
                >
                  <Submenu child={filteredChild} />
                </Link>
              ))}
          </Stack>
        </Collapse>
      </>
    </>
  );
}

import { PATH_DOCS, PATH_PAGE99, PATH_SUPPLIER } from '@/routes/paths';
import { Event, EventAction, EventCategory } from '@/utils/goggleAnalytics/gtag';

import type { NavMenu } from './types';

const navConfig: NavMenu[] = [
  {
    title: 'COMMON.MENU.PRODUCTS',
    path: '#',
    countries: ['CL', 'CO', 'PE', 'MX'],
    event: {
      action: EventAction.CLICK,
      category: EventCategory.PRODUCTS,
      event: Event.M_PRODUCTO,
      value: 'Productos',
    },
    children: [
      {
        img: '/assets/icons/home/universe/envios99_logo.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.DELIVERY99',
        link: '/envios99',
        width: 90,
        height: 40,
        countries: ['CO', 'PE', 'MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_ENVIOS99,
          value: 'Envios99',
        },
      },
      {
        img: '/assets/icons/home/universe/envios99_logo.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.DELIVERY99_CL',
        link: '/envios99',
        width: 90,
        height: 40,
        countries: ['CL'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_ENVIOS99,
          value: 'Envios99_CL',
        },
      },
      {
        img: '/assets/images/logos/tailormade.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.TAILOR',
        link: '/tailor99',
        width: 77,
        height: 40,
        countries: ['CO', 'PE', 'MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_TLM,
          value: 'Tailor99',
        },
      },
      {
        img: '/assets/images/logos/tailormade.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.TAILOR_CL',
        link: '/tailor99',
        width: 77,
        height: 40,
        countries: ['CL'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_TLM,
          value: 'Tailor99_CL',
        },
      },
      {
        img: '/assets/images/logos/fulfill99.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.FULFILL99',
        descriptionCL: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.FULFILL99CL',
        link: '/fulfill99',
        width: 80,
        height: 40,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_FULFILL99,
          value: 'Fulfill99',
        },
      },
      {
        img: '/assets/images/logos/cash99.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.CASH99',
        link: '/cash99',
        width: 75,
        height: 40,
        countries: ['CO', 'MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_CASH99,
          value: 'Cash99',
        },
      },
      {
        img: '/assets/images/logos/punto99.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.PUNTO99',
        link: '/punto99',
        width: 85,
        height: 40,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_PUNTO99,
          value: 'Punto99',
        },
      },
      /*
      {
        img: '/assets/images/logos/ruta99.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.RUTA99',
        descriptionCL: 'LAYOUT.HEADER.SUBMENUS.PRODUCTS.RUTA99CL',
        link: '/ruta99',
        width: 75,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.PRODUCTS,
          event: Event.SM_RUTA99,
          value: 'Ruta99',
        },
      },
      */
    ],
  },
  {
    title: 'COMMON.MENU.INTEGRATIONS',
    countries: ['CL', 'CO', 'PE', 'MX'],
    path: '#',
    event: {
      action: EventAction.CLICK,
      category: EventCategory.INTEGRATIONS,
      event: Event.M_INTEGRACIONES,
      value: 'Integraciones',
    },
    children: [
      {
        img: '/assets/images/brands/Logo_Shopify.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.SHOP',
        link: 'https://developers.99minutos.com/page/shopify',
        width: 105,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_SHOPIFY,
          value: 'Shopify',
        },
      },
      {
        img: '/assets/images/logos/woocommerce.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.WC',
        link: 'https://developers.99minutos.com/page/plugin-installation-woocommerce',
        width: 105,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_WOOCOMMERCE,
          value: 'WooCommerce',
        },
      },
      {
        img: '/assets/images/brands/Logo_Magento.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.MAG',
        link: 'https://example-99mxn.readme.io/page/plugin-installation-magento',
        width: 95,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_MAGENTO,
          value: 'Magento',
        },
      },
      {
        img: '/assets/images/brands/Logo_tiendanube.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.TIENDA',
        link: 'https://example-99mxn.readme.io/page/plugin-installation-tienda-nube',
        width: 115,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_TIENDANUBE,
          value: 'TiendaNube',
        },
      },
      {
        img: '/assets/images/brands/Logo_Jumpseller.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.JS',
        link: 'https://example-99mxn.readme.io/page/plugin-installation-jumpseller',
        width: 105,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_JUMPSELLER,
          value: 'Jumpseller',
        },
      },
      {
        img: '/assets/images/brands/Logo_Prestashop.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.PS',
        link: 'https://example-99mxn.readme.io/page/plugin-installation-prestashop',
        width: 105,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_PRESTASHOP,
          value: 'Prestashop',
        },
      },
      {
        img: '/assets/images/brands/Logo_BigCommerce.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.BC',
        link: 'https://example-99mxn.readme.io/page/api-installation-bigcommerce',
        width: 100,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_BIGCOMMERCE,
          value: 'BigCommerce',
        },
      },
      {
        img: '/assets/images/brands/Logo_mercadoEnvios.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.MEEN',
        link: 'https://developers.99minutos.com/page/meli-flex',
        width: 105,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_MELIFLEX,
          value: 'MercadoEnvios',
        },
      },
      {
        img: '/assets/images/logos/08_API.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.INTEGRATIONS.API',
        link: 'https://developers.99minutos.com/',
        width: 124,
        height: 40,
        countries: ['CL', 'CO', 'PE', 'MX'],
        external: true,
        event: {
          action: EventAction.CLICK,
          category: EventCategory.INTEGRATIONS,
          event: Event.SM_API,
          value: 'API',
        },
      },
    ],
  },
  {
    title: 'COMMON.MENU.WORK_WITH_US',
    countries: ['MX'],
    path: '#',
    event: {
      action: EventAction.CLICK,
      category: EventCategory.WORK_WITH_US,
      event: Event.M_TRABAJACONNOSOTROS,
      value: 'Trabaja con nosotros',
    },
    children: [
      {
        img: '/assets/images/icons/icon_repartidorfreelance.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.DESCRIPTIONS.FREELANCE',
        name: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.TITLES.FREELANCE',
        link: '/freelance',
        width: 24,
        height: 24,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.WORK_WITH_US,
          event: Event.SM_FREELANCE,
          value: 'Freelance',
        },
      },
      {
        img: '/assets/images/icons/icon_centroReparto_punto99.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.DESCRIPTIONS.PUNTO99',
        name: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.TITLES.PUNTO99',
        link: '/punto99-delivery-center',
        width: 24,
        height: 24,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.WORK_WITH_US,
          event: Event.SM_PUNTO99WORK,
          value: 'Punto99yCentroDeReparto',
        },
      },
      {
        img: '/assets/images/icons/icon_repartidorBase.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.DESCRIPTIONS.BASE_DISTRIBUTORS',
        name: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.TITLES.BASE_DISTRIBUTORS',
        link: '/base-distributors',
        width: 24,
        height: 24,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.WORK_WITH_US,
          event: Event.SM_BASE_DISTRIBUTORS,
          value: 'RepartidoresBase',
        },
      },
      {
        img: '/assets/images/icons/icon_aliadosestrategicos.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.DESCRIPTIONS.STRATEGIC_ALLIES',
        name: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.TITLES.STRATEGIC_ALLIES',
        link: '/strategic-allies',
        width: 24,
        height: 24,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.WORK_WITH_US,
          event: Event.SM_STRATEGIC_ALLIES,
          value: 'AliadosEstrategicos',
        },
      },
      {
        img: '/assets/images/icons/workWithUs/servicePartner/icon_ServicePartner.svg',
        description: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.DESCRIPTIONS.SERVICE_PROVIDERS',
        name: 'LAYOUT.HEADER.SUBMENUS.WORK_WITH_US.TITLES.SERVICE_PROVIDERS',
        link: '/service-partner',
        width: 24,
        height: 24,
        countries: ['MX'],
        event: {
          action: EventAction.CLICK,
          category: EventCategory.WORK_WITH_US,
          event: Event.SM_SERVICE_PARTNER,
          value: 'SocioDeServicio',
        },
      },
    ],
  },
  {
    title: 'COMMON.MENU.RATES',
    countries: ['CO', 'PE', 'MX'],
    path: PATH_PAGE99.rates,
    event: {
      action: EventAction.CLICK,
      category: EventCategory.HOME,
      event: Event.M_TARIFAS,
      value: 'Tarifas',
    },
  },
  {
    title: 'COMMON.MENU.SUPPLIER',
    path: PATH_SUPPLIER,
    external: true,
    countries: ['CL'],
    event: {
      action: EventAction.CLICK,
      category: EventCategory.HOME,
      event: Event.M_PROVEEDOR,
      value: 'Proveedor',
    },
  },
  {
    title: 'COMMON.MENU.SUPPORT',
    countries: ['CL', 'PE', 'MX'],
    path: PATH_DOCS.support,
    external: true,
    event: {
      action: EventAction.CLICK,
      category: EventCategory.HOME,
      event: Event.M_SOPORTE,
      value: 'Soporte',
    },
  },
  {
    title: 'COMMON.MENU.SUPPORT',
    countries: ['CO'],
    path: PATH_DOCS.support_col,
    external: true,
    event: {
      action: EventAction.CLICK,
      category: EventCategory.HOME,
      event: Event.M_SOPORTE,
      value: 'Soporte',
    },
  },
  {
    title: 'COMMON.MENU.BLOG',
    countries: ['CL', 'CO', 'PE', 'MX'],
    path: '/blog',
    event: {
      action: EventAction.CLICK,
      category: EventCategory.HOME,
      event: Event.M_BLOG,
      value: 'Blog',
    },
  },
];

export default navConfig;

// @ts-nocheck

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';

import Iconify from '@/components/iconify/Iconify';
import Scrollbar from '@/components/scrollbar/Scrollbar';
import { useLayoutStore } from '@/stores/layout/layout.store';

import Buttons from './Buttons';
import NavList from './NavList';

import type { NavProps } from '../types';

export default function NavMobile({ isOffset, data }: NavProps) {
  const { pathname } = useRouter();
  const [open, setOpen] = useState(false);
  const country = useLayoutStore((state) => state.country);

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{ ...(isOffset && { color: 'text.primary' }) }}
        id="hamburger-menu"
        aria-label="Hamburger Menu"
      >
        <Iconify
          icon={open ? 'eva:close-outline' : 'eva:menu-fill'}
          color="primary.main"
          width={30}
        />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        BackdropProps={{ sx: { backgroundColor: 'transparent' } }}
        PaperProps={{ sx: { pb: 5, width: '100%', top: '5.3rem !important' } }}
      >
        <Scrollbar>
          <List component="nav" disablePadding>
            {data.map(
              (item) => item.countries.includes(country) && <NavList key={item.link} item={item} />,
            )}
          </List>
          <Box sx={{ height: '1.5rem' }} />
          <Buttons />
        </Scrollbar>
      </Drawer>
    </>
  );
}

import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';

export const MainBox = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
}));

export const SeparatorBox = styled(Box)(({ theme }) => ({
  borderTop: `solid 1px ${alpha(theme.palette.grey[800], 0.12)}`,
  width: '100%',
}));

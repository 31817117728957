// @ts-nocheck

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Portal } from '@mui/material';
import { useLayoutStore } from 'src/stores/layout/layout.store';

import useActiveLink from '@/hooks/useActiveLink';
import { mapEvent } from '@/utils/goggleAnalytics/gtag';

import Submenu from '../Submenu/Submenu';
import { NavItem } from './NavItem';
import { StyledMenu, StyledPopover } from './styles';

import type { NavMenu } from '../types';

interface NavListProps {
  item: NavMenu;
  isOffset: boolean;
  depth: number;
  hasChild: boolean;
}

export default function NavList({ item, isOffset, depth, hasChild }: NavListProps) {
  const { pathname } = useRouter();
  const navRef = useRef(null);
  const [open, setOpen] = useState(false);
  const country = useLayoutStore((state) => state.country);
  const { path, children } = item;

  const { active, isExternalLink } = useActiveLink(path, false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [pathname]);

  useEffect(() => {
    const appBarEl = Array.from(document.querySelectorAll('.MuiAppBar-root')) as Array<HTMLElement>;

    // Reset styles when hover
    const styles = () => {
      document.body.style.overflow = '';
      document.body.style.padding = '';
      // Apply for Window
      appBarEl.forEach((elem) => {
        elem.style.padding = '';
      });
    };

    if (open) {
      styles();
    } else {
      styles();
    }
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        ref={navRef}
        item={item}
        isOffset={isOffset}
        active={active}
        open={open}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        isExternalLink={isExternalLink}
        depth={depth}
      />

      <Portal>
        {!!children && (
          <StyledPopover
            open={open}
            PaperProps={{
              onMouseEnter: handleOpen,
              onMouseLeave: handleClose,
            }}
            sx={{ minHeight: '100vh' }}
          >
            <StyledMenu sx={{ display: 'flex', gap: '1rem', width: '100%', flexWrap: 'wrap' }}>
              {children
                .filter((child) => child.countries.includes(country))
                .map((filteredChild) => (
                  <Link
                    href={filteredChild.link}
                    target={filteredChild.external === true ? '_blank' : ''}
                    style={{ textDecoration: 'none', width: '30%' }}
                    key={filteredChild.name}
                    onClick={() => mapEvent(filteredChild.event)}
                  >
                    <Submenu child={filteredChild} />
                  </Link>
                ))}
            </StyledMenu>
          </StyledPopover>
        )}
      </Portal>
    </>
  );
}

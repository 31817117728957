// @ts-nocheck

import Box from '@mui/material/Box';
import { alpha, styled } from '@mui/material/styles';

export const SubMenuBox = styled(Box)(({ theme, style }) => ({
  transition: 'background-color 0.3s ease',
  borderRadius: '0.5rem',
  ...style,
  [theme.breakpoints.down('lg')]: {
    border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
    margin: '1vh 3vh',
  },
}));

// @ts-nocheck
import Link from 'next/link';
import { forwardRef } from 'react';

import { CardActionArea } from '@mui/material';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import Iconify from '@/components/iconify/Iconify';
import Image from '@/components/image/Image';

import { ListItem } from './styles';

import type { NavItemDesktopProps, NavItemProps } from '../types';

interface NavItemDashboardProps extends LinkProps {
  item: NavItemProps;
}

export const NavItem = forwardRef<HTMLDivElement, NavItemDesktopProps>(function NavItem(
  { item, open, isOffset, active, subItem, isExternalLink, openCloseModal, ...other },
  ref,
) {
  const { title, path, children, external } = item;
  const { t } = useTranslation();

  const renderContent = (
    <ListItem
      ref={ref}
      disableRipple
      isOffset={isOffset}
      subItem={subItem}
      active={active}
      open={open}
      {...other}
    >
      {t(title)}
      {!(children == null) && (
        <Iconify width={16} icon="eva:arrow-ios-downward-fill" sx={{ ml: 0.5 }} />
      )}
    </ListItem>
  );

  // ExternalLink
  if (isExternalLink ?? false) {
    return (
      <Link href={path} target={external ? '_blank' : ''} style={{ textDecoration: 'none' }}>
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children != null) {
    return renderContent;
  }

  // Default
  return (
    <Link
      href={path}
      target={external ? '_blank' : ''}
      style={{ textDecoration: 'none' }}
      onClick={openCloseModal}
    >
      {renderContent}
    </Link>
  );
});

export function NavItemDashboard({ item, sx, ...other }: NavItemDashboardProps) {
  return (
    <Link href={item.path} underline="none" sx={{ width: 1 }} {...other}>
      <CardActionArea
        sx={{
          py: 5,
          px: 10,
          minHeight: 400,
          borderRadius: 1,
          color: 'text.disabled',
          bgcolor: 'background.neutral',
          ...sx,
        }}
      >
        <m.div
          whileTap="tap"
          whileHover="hover"
          variants={{
            hover: { scale: 1.02 },
            tap: { scale: 0.98 },
          }}
        >
          <Image
            visibleByDefault
            alt="illustration_dashboard"
            src="/assets/illustrations/illustration_dashboard.png"
          />
        </m.div>
      </CardActionArea>
    </Link>
  );
}

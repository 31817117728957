import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface TitleTextProps {
  isTitle?: boolean;
  center?: boolean;
}

interface SubtitleBodyProps {
  strong?: boolean;
  margin?: string;
}

interface BodyTextProps {
  strong?: boolean;
  bold?: boolean;
  margin?: string;
}

interface SmallBodyTextProps {
  strong?: boolean;
}

interface SecondaryTitleTextH4Props {
  bold?: boolean;
}
// Titulos

export const TitleTextH3 = styled(Typography)<TitleTextProps>(({ theme, isTitle }) => ({
  ...theme.typography.h3,
  color: isTitle ?? false ? theme.palette.primary.main : theme.palette.secondary.main,
  fontWeight: isTitle ?? false ? theme.typography.h3.fontWeight : theme.typography.fontWeightLight,
  lineHeight: '2.6rem !important',
}));

export const TitleH4 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.main,
}));

export const TitleTextH4 = styled(Typography)<TitleTextProps>(({ theme, isTitle, center }) => ({
  ...theme.typography.h4,
  color: isTitle ?? false ? theme.palette.primary.main : theme.palette.secondary.main,
  fontWeight: isTitle ?? false ? theme.typography.h3.fontWeight : theme.typography.fontWeightLight,
}));

export const TitleH5 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.main,
}));
export const TitleH5WhiteBold = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: theme.typography.fontWeightBold,
  color: 'white',
}));

export const TitleTextH5Green = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.primary.main,
}));

export const TitleH6 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.palette.secondary.main,
}));
export const TitleH6SemiBold = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.main,
}));

export const TitleTextH6Green = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const TitleTextMiddle = styled(Typography)<TitleTextProps>(({ theme, isTitle }) => ({
  fontSize: '30px',
  color: isTitle ?? false ? theme.palette.primary.main : theme.palette.secondary.main,
  fontWeight: isTitle ?? false ? theme.typography.h3.fontWeight : theme.typography.fontWeightLight,
  [theme.breakpoints.down('md')]: {
    fontSize: '25px',
    lineHeight: '30px',
  },
}));

// Subtitulos

export const SecondaryTitleTextH3 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const SecondaryTitleTextH4 = styled(Typography)<SecondaryTitleTextH4Props>(
  ({ theme, bold }) => ({
    ...theme.typography.h4,
    color: theme.palette.secondary.main,
    fontWeight: bold ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
  }),
);

export const SecondaryTitleTextH5 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const SubtitleBody = styled(Typography)<SubtitleBodyProps>(({ theme, strong }) => ({
  ...theme.typography.subtitle1,
  color: strong ?? false ? theme.palette.primary.main : theme.palette.secondary.main,
  marginTop: strong ?? false ? 'auto' : 1,
  fontWeight: strong ?? false ? 'inherit' : theme.typography.fontWeightMedium,
}));
export const SubtitleBody2 = styled(Typography)<SubtitleBodyProps>(({ theme, margin }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightRegular,
  margin: margin ?? '0',
}));

// Body

export const BodyText = styled(Typography)<BodyTextProps>(({ theme, strong, bold, margin }) => ({
  ...theme.typography.body1,
  color: strong ?? false ? theme.palette.primary.main : 'inherit',
  fontWeight: bold ?? false ? theme.typography.fontWeightMedium : 'inherit',
  margin: margin ?? '0.5rem 0 0 0',
}));

export const SmallBodyText = styled(Typography)<SmallBodyTextProps>(({ theme, strong }) => ({
  ...theme.typography.body2,
  fontSize: '0.750rem',
  color: strong ?? false ? theme.palette.primary.main : 'inherit',
  marginTop: strong ?? false ? 'auto' : 1,
}));

// Otros

export const StrongGreen = styled('strong')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

// @ts-nocheck

import Image from 'next/image';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

import { useLayoutStore } from '@/stores/layout/layout.store';

import { SubMenuBox } from './styles';

import type { SxProps, Theme } from '@mui/material';

import type { NavSubmenu } from '../types';

interface SubmenuProps {
  child: NavSubmenu;
  style?: SxProps<Theme>;
}

const Submenu = ({ child, style }: SubmenuProps) => {
  const { t } = useTranslation();
  const country = useLayoutStore((state) => state.country);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <SubMenuBox
      key={child.description}
      style={style}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      sx={{ backgroundColor: isHovered ? 'grey.50' : 'white', p: '2vh', minHeight: '10rem' }}
    >
      <Box display="flex" alignItems="center" gap={1} mb={child.name !== undefined ? '1rem' : '0'}>
        <Image
          src={child.img}
          width={child.width}
          height={child.height}
          alt="icon product"
          loading="lazy"
        />

        {child.name !== undefined && (
          <Typography
            sx={{
              color: (theme) => theme.palette.grey.main,
              fontWeight: (theme) => theme.typography.fontWeightBold,
            }}
          >
            {t(child.name)}
          </Typography>
        )}
      </Box>

      <Trans>
        <Typography
          sx={{
            fontSize: '0.9rem',
            color: (theme) => theme.palette.grey[500],
            mt: '0.5rem',
          }}
        >
          {country === 'CL' && child.descriptionCL !== undefined
            ? t(child.descriptionCL)
            : t(child.description)}
        </Typography>
      </Trans>
    </SubMenuBox>
  );
};
export default Submenu;

// @ts-nocheck
import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import getRatio from './getRatio';

import type { ImageProps } from './types';

const Image = forwardRef<HTMLSpanElement, ImageProps>(function Image(
  { ratio, disabledEffect = false, effect = 'blur', sx, ...other },
  ref,
) {
  const content = (
    <Box
      component={LazyLoadImage}
      wrapperClassName="wrapper"
      effect={disabledEffect ? undefined : effect}
      placeholderSrc={disabledEffect ? '/assets/transparent.png' : '/assets/placeholder.svg'}
      sx={{ width: 1, height: 1, objectFit: 'cover' }}
      {...other}
    />
  );

  if (ratio) {
    return (
      <Box
        ref={ref}
        component="span"
        sx={{
          width: 1,
          lineHeight: 1,
          display: 'block',
          overflow: 'hidden',
          position: 'relative',
          pt: getRatio(ratio),
          '& .wrapper': {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
            position: 'absolute',
            backgroundSize: 'cover !important',
          },
          ...sx,
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Box
      ref={ref}
      component="span"
      sx={{
        lineHeight: 1,
        display: 'block',
        overflow: 'hidden',
        position: 'relative',
        '& .wrapper': {
          width: 1,
          height: 1,
          backgroundSize: 'cover !important',
        },
        ...sx,
      }}
    >
      {content}
    </Box>
  );
});

export default Image;

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { alpha, styled } from '@mui/material/styles';

import { NAV } from '@/config-global';

import type { NavItemMobileProps } from '../types';

type ListItemProps = Omit<NavItemMobileProps, 'item'>;

export const ListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<ListItemProps>(({ active, theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  height: NAV.H_DASHBOARD_ITEM,
  // Active
  ...(active === true && {
    color: theme.palette.primary.main,
    ...theme.typography.subtitle2,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
}));

export const SeparatorBox = styled(Box)(({ theme }) => ({
  borderTop: `solid 1px ${alpha(theme.palette.grey[800], 0.12)}`,
  width: '100%',
}));

export const ItemBox = styled(Box)(({ theme }) => ({
  marginLeft: '2rem',
  marginRight: '2rem',
}));
